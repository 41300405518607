export const products = [
  {
    _id: '1',
    name: 'Producto 1',
    description: 'Descripción Producto 1',
    price: 12000,
    company: '1'
  },
  {
    _id: '2',
    name: 'Producto 2',
    description: 'Descripción Producto 2',
    price: 12000,
    company: '1'
  },
  {
    _id: '3',
    name: 'Producto 3',
    description: 'Descripción Producto 3',
    price: 12000,
    company: '1'
  },
  {
    _id: '11',
    name: 'Producto 1',
    description: 'Descripción Producto 1',
    price: 12000,
    company: '2'
  },
  {
    _id: '12',
    name: 'Producto 2',
    description: 'Descripción Producto 2',
    price: 12000,
    company: '2'
  },
  {
    _id: '13',
    name: 'Producto 3',
    description: 'Descripción Producto 3',
    price: 12000,
    company: '2'
  },
  {
    _id: '21',
    name: 'Producto 1',
    description: 'Descripción Producto 1',
    price: 12000,
    company: '3'
  },
  {
    _id: '22',
    name: 'Producto 2',
    description: 'Descripción Producto 2',
    price: 12000,
    company: '3'
  },
  {
    _id: '23',
    name: 'Producto 3',
    description: 'Descripción Producto 3',
    price: 12000,
    company: '3'
  },
]

export default []
