import { SET_USER, SET_LOGGED, SET_TOKEN } from "../actions"

const INITIAL_STATE = {
  user: {},
  logged: false
}

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: { ...action.payload.user } }
    case SET_LOGGED:
      return { ...state, logged: action.payload.logged }
    case SET_TOKEN:
      return { ...state, token: action.payload.token }
    default:
      return state
  }
}