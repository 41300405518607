import { SET_ORDERS, SET_SELECTED_ORDER, SET_ORDERS_FILTER, SET_ERRANDS } from '../actions'

const INITIAL_STATE = {
  orders: [],
  selectedOrder: null,
  filter: [],
  errands: [],
}

export const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return { ...state, orders: [...action.payload.orders] }
    case SET_SELECTED_ORDER:
      return { ...state, selectedOrder: { ...action.payload.order } }
    case SET_ORDERS_FILTER:
      return { ...state, filter: [...action.payload.filter] }
    case SET_ERRANDS:
      return { ...state, errands: [...action.payload.errands] }
    default:
      return state
  }
}