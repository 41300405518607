import { SET_PRODUCTS } from '../actions'

import { products } from '../../data/products'

const productsMap = {}

products.forEach(p => productsMap[p._id] = p)

const INITIAL_STATE = {
  products: productsMap,
}

export const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return { ...state, products: action.payload.products }
    default:
      return state
  }
}