export const env = {
  server: process.env.REACT_APP_API_URL,
  ws: process.env.REACT_APP_WS_URL,
  firebase: {
    apiKey: "AIzaSyChaxIs3AJ2gp3NAWaAuVz-3p1rKf0vseg",
    authDomain: "agus-322921.firebaseapp.com",
    projectId: "agus-322921",
    storageBucket: "agus-322921.appspot.com",
    messagingSenderId: "443924087077",
    appId: "1:443924087077:web:a09ce7ace2519dc87e9acc",
    measurementId: "G-7GL0FBGJZV"
  }
}