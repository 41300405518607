import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';

import { ordersReducer } from './orders/reducer'
import { userReducer } from './user/reducer'
import { companiesReducer } from './companies/reducer'
import { productsReducer } from './products/reducer'

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  chatApp,
  surveyListApp,
  surveyDetailApp,

  orders: ordersReducer,
  user: userReducer,
  companies: companiesReducer,
  products: productsReducer,
});

export default reducers;