import { SET_COMPANIES } from '../actions'

const INITIAL_STATE = {
  companies: {},
}

export const companiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPANIES:
      return { ...state, companies: { ...action.payload.companies } }
    default:
      return state
  }
}