/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const firebaseConfig = {
  apiKey: "AIzaSyChaxIs3AJ2gp3NAWaAuVz-3p1rKf0vseg",
  authDomain: "agus-322921.firebaseapp.com",
  projectId: "agus-322921",
  storageBucket: "agus-322921.appspot.com",
  messagingSenderId: "443924087077",
  appId: "1:443924087077:web:a09ce7ace2519dc87e9acc",
  databaseURL: "https://agus-322921-default-rtdb.firebaseio.com",
  measurementId: "G-7GL0FBGJZV"
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = "__theme_color"
export const isMultiColorActive = false;
export const defaultColor = "light.blue";
export const isDarkSwitchActive = false;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
