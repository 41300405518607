import { env } from '../config/environment'

let ws

export const initWS = (
  onMessage
) => {
  ws = new WebSocket(env.ws)
  _on(ws, onMessage, 1)
}

const _on = (ws, onMessage, count) => {
  ws.onopen = evt => {
    console.log('Conexión establecida.')
    count = 0
  }
  ws.onmessage = onMessage
  ws.onclose = evt => {
    console.log('Conexión cerrada')
    if (ws) {
      ws.close()
      ws = null
    }
    if (count < 5) {
      ws = new WebSocket(env.ws)
      _on(ws, onMessage, count + 1)
    }
  }
  ws.onerror = evt => {
    console.log("ERROR: " + evt.data)
    if (count < 5) {
      ws = new WebSocket(env.ws)
      _on(ws, onMessage, count + 1)
    }
  }
}

export const closeWS = () => {
  if (ws) {
    ws.close()
    ws = null
  }
}

initWS()